import { makeStyles } from "tss-react/mui";

export const useHorizontalPostGridStyles = makeStyles()((theme) => ({
  wrapper: {
    maxWidth: "100%",
    overflowX: "scroll",
    width: "100%",
    "& h2": {
      marginTop: theme.spacing(1.2),
    },
  },
  inner: {
    margin: theme.spacing(1, 0),
    width: "100%",
    display: "flex",
  },
  article: {
    width: "300px",
    minWidth: "300px",
    "@media (max-width: 500px)": {
      width: "250px !important",
      minWidth: "250px",
    },
    padding: "0 !important",
    marginRight: theme.spacing(1),
  },
  titleInHorizontalGrid: {
    fontSize: "24px",
    marginTop: theme.spacing(1),
  },
  imagejo: {
    "& .gatsby-image-wrapper": {
      height: "300px",
      width: "300px",
      "@media (max-width: 500px)": {
        width: "250px !important",
        height: "250px !important",
      },
    },
  },
  horizontalGridWrapper: {
    marginBottom: theme.spacing(7),
    marginTop: theme.spacing(1),
  },
}));
