import { Box, Link as MuiLink, Typography } from "@mui/material";
import { Link as GatsbyLink } from "gatsby";
import { mapWpImageInformation } from "../../../helpers/clientSide/WordPress/mapWpImageInformation";
import ArticlePreview from "../../02-molecules/ArticlePreview/ArticlePreview";
import { HorizontalPostGridProps } from "./HorizontalPostGridInterfaces";
import { useHorizontalPostGridStyles } from "./HorizontalPostGridStyles";

const HorizontalPostGrid = (props: HorizontalPostGridProps): JSX.Element => {
  const { classes } = useHorizontalPostGridStyles();

  // console.log(props.categoryArticlesPreviewData);

  return (
    <Box className={classes.horizontalGridWrapper} width="100%">
      <MuiLink component={GatsbyLink} to={`/kategorie/${props.categorySlug}`}>
        <Typography variant="h5" color="secondary.dark">
          {props.categoryLabel}
        </Typography>
      </MuiLink>

      <Box component="section" className={classes.wrapper}>
        <Box className={classes.inner}>
          {props.categoryArticlesPreviewData.map((article, index) => {
            const mappedImage = article.featuredImage
              ? // Übersetzer WP zu Gatsby Image
                mapWpImageInformation(article.featuredImage?.node)
              : undefined;

            return (
              <ArticlePreview
                classNameArticle={classes.article}
                classNameTitle={classes.titleInHorizontalGrid}
                classNameImage={classes.imagejo}
                key={index}
                title={article.title}
                excerpt={article.excerpt}
                link={article.slug}
                image={mappedImage}
                categoryLabel={" "}
                isInsideOfHorizontalSlider={true}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default HorizontalPostGrid;
