import { Box, Container, Grid, Typography } from "@mui/material";
import { graphql } from "gatsby";
import { pathForCategory } from "../../../helpers/general/links/pathForCategory";
import { getMainCategoryForArticle } from "../../../helpers/wordpress/getMainCategoryForArticle";
import { ChipLinkProps } from "../../01-atoms/ChipLink/ChipLinkInterfaces";
import { SeoBasics } from "../../01-atoms/SeoBasics/SeoBasics";
import { ChipStack } from "../../02-molecules/ChipStack/ChipStack";
import HorizontalPostGrid from "../../03-organisms/HorizontalPostGrid/HorizontalPostGrid";
import { CategoryTemplateProps } from "./CategoryTemplateInterfaces";
import { useCategoryTemplateStyles } from "./CategoryTemplateStyles";

export const query = graphql`
  query ($id: String!) {
    posts: allWpPost(
      sort: { order: DESC, fields: date }
      filter: { categories: { nodes: { elemMatch: { id: { eq: $id } } } } }
    ) {
      nodes {
        ...WpPostFields
      }
    }
    allCategories: allWpCategory {
      nodes {
        id
        name
        parentId
        slug
      }
    }
    subcategories: allWpCategory(filter: { parentId: { eq: "dGVybTozNg==" } }) {
      nodes {
        id
        slug
      }
    }

    allPostsWithSubCategoryFilter: allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { id: { eq: "dGVybTo1Ng==" } } } }
      }
    ) {
      nodes {
        ...WpPostFields
      }
    }
  }
`;

const CategoryTemplate = (props: CategoryTemplateProps): JSX.Element | null => {
  const { data, pageContext } = props;
  const { classes } = useCategoryTemplateStyles();

  console.log(data);

  const thisCategoryId = pageContext.id;

  const articles = data.posts?.nodes || [];
  console.log(articles);

  const subCategories = (data.allCategories?.nodes || []).filter(
    (cat) => cat.parentId === thisCategoryId
  );
  console.log(subCategories);

  // const allPostsWithSubCategoryFilter =
  //   data.allPostsWithSubCategoryFilter?.nodes || [];

  // const firstPostsWithSubCategory =
  //   data.allPostsWithSubCategoryFilter?.nodes?.[0];

  // const subCategoryOfFirstPost = firstPostsWithSubCategory
  //   ? getSubCategoryForArticle(firstPostsWithSubCategory)
  //   : undefined;

  // const hasEnoughData = articles.length && subCategoryOfFirstPost;

  // Kann man das oben auskommentieren?

  const hasEnoughData = articles.length;

  if (!hasEnoughData) {
    return null;
  }

  const chips: ChipLinkProps[] = subCategories.map((subCategory) => ({
    isActive: false,
    href: pathForCategory(subCategory),
    label: subCategory.name,
  }));

  const mainCategoryTitle = getMainCategoryForArticle(articles[0]);

  // console.log(mainCategoryTitle);

  return (
    <Container className={classes.wrapper}>
      {/* TODO: add category seo  */}

      <SeoBasics
        description={`Hier findest du alle Artikel rund um das Thema ${mainCategoryTitle}`}
        title={`politikjam | ${mainCategoryTitle}`}
      />

      <Grid
        container={true}
        direction="column"
        alignItems="center"
        item={true}
        xs={12}
        mb={2.5}
        mt={2.5}
      >
        <Typography mb={2} variant="h1">
          {getMainCategoryForArticle(articles[0])}
        </Typography>

        <ChipStack chips={chips} />

        {/* Macht erst Sinn, wenn genügend Artikel in den Unterkategorien sind */}

        {/* <HorizontalPostGrid
          categoryLabel={subCategoryOfFirstPost?.toUpperCase()}
          categoryArticlesPreviewData={allPostsWithSubCategoryFilter}
        /> */}

        {/* {sortedCategories.map((cat, index) => {
          const arrayWithFilteredArticlesByCat =
            data.allArticlesExeptOfTopArticleAndVerticalArticles.nodes.filter(
              (article) => {
                return article.categories.nodes
                  .map((category) => category.id)
                  .includes(cat.id);
              }
            ); */}

        {/*
 FÜR JEDE UNTERKAT EIN HORIZONTAL GRID START */}

        {subCategories.map((subcat, index) => {
          return (
            <Box width="100%" key={index}>
              <HorizontalPostGrid
                categoryLabel={subcat.name.toUpperCase()}
                categoryArticlesPreviewData={articles.filter((article) =>
                  article.categories?.nodes?.some(
                    (cat) => cat?.id === subcat.id
                  )
                )}
                categorySlug={subcat.slug}
              />
            </Box>
          );
        })}

        {/* END */}
      </Grid>
    </Container>
  );
};

export default CategoryTemplate;
